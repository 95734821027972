const actonBranchMenu = [
    {
        label: 'starters',
        description: '',
        menuItems: [
            { name: 'Miso Soup', description: '(VG)', price: '3.50', ingredients: '' },
            { name: 'Edamame Normal', description: '(VG)', price: '3.99', ingredients: '' },
            { name: 'Edamame Spicy', description: '(VG)', price: '3.99', ingredients: '' },
            { name: 'Chicken Karaage', description: '(5 pc)', price: '5.00', ingredients: '' },
            { name: 'Veg Spring Roll', description: '(3 pc, VG)', price: '3.99', ingredients: '' },
            { name: 'Prawn Gyoza', description: '(5 pc)', price: '5.99', ingredients: '' },
            { name: 'Chicken Gyoza', description: '(5 pc)', price: '5.50', ingredients: '' },
            { name: 'Duck Gyoza', description: '(5 pc)', price: '5.99', ingredients: '' },
            { name: 'Veg Gyoza', description: '(5 pc, VG)', price: '4.99', ingredients: '' },
            { name: 'Prawn Tempura', description: '(5 pc)', price: '8.50', ingredients: '' },
            { name: 'Chicken Yakitori', description: '(5 pc)', price: '4.99', ingredients: '' },
            { name: 'Sunomono Salad', description: '(5 pc)', price: '4.99', ingredients: '' },
        ]
    },
    {
        label: 'nigiri',
        description: '2 pieces of rice pillow with toppings',
        menuItems: [
            { name: 'Salmon Nigiri', description: '', price: '3.50', ingredients: '' },
            { name: 'Tuna Nigiri', description: '', price: '3.99', ingredients: '' },
            { name: 'Seabass Nigiri', description: '', price: '3.50', ingredients: '' },
            { name: 'Eel Nigiri', description: '', price: '4.99', ingredients: '' },
            { name: 'Prawn Nigiri', description: '', price: '3.99', ingredients: '' },
            { name: 'Mango Nigiri', description: '(VG)', price: '3.50', ingredients: '' },
            { name: 'Avocado Nigiri', description: '(VG)', price: '3.50', ingredients: '' },
        ]
    },
    {
        label: 'gunkan',
        description: '2 pieces of nori-wrapped sushi rice topped with a choice of ingredient',
        menuItems: [
            { name: 'Salmon Gunkan', description: '', price: '3.00', ingredients: '' },
            { name: 'Tuna Gunkan', description: '', price: '3.50', ingredients: '' },
            { name: 'Masago Gunkan', description: '', price: '3.00', ingredients: '' },
            { name: 'Wakame Gunkan', description: '(vg)', price: '2.50', ingredients: '' },
        ]
    },
    {
        label: 'tartare',
        description: 'ground raw fish with sauce and dressing',
        menuItems: [
            { name: 'Salmon Tartare', description: '', price: '7.50', ingredients: '' },
            { name: 'Tuna Tartare', description: '', price: '7.99', ingredients: '' },
        ]
    },
    {
        label: 'tataki',
        description: '5 slices of seared fish',
        menuItems: [
            { name: 'Salmon Tataki', description: '', price: '6.99', ingredients: '' },
            { name: 'Tuna Tataki', description: '', price: '7.50', ingredients: '' },
        ]
    },
    {
        label: 'temaki',
        description: 'a traditional Japanese handroll w/ rice & fillings',
        menuItems: [
            { name: 'Salmon Temaki', description: '', price: '5.50', ingredients: 'salmon / japanese mayo / sesame seeds / avocado' },
            { name: 'Tuna Temaki', description: '', price: '5.99', ingredients: 'tuna / japanese mayo / green onion' },
            { name: 'Salmon Philadelphia Temaki', description: '', price: '5.50', ingredients: 'salmon / cream cheese / avocado / spring onion / tobiko' },
            { name: 'Vegatarian Temaki', description: '(vg)', price: '4.50', ingredients: 'avocado / cucumber / salad / mango' },
        ]
    },
    {
        label: 'chirashi',
        description: 'sushi rice topped with raw fish',
        menuItems: [
            { name: 'Salmon Chirashi', description: '', price: '8.50', ingredients: '' },
            { name: 'Tuna Chirashi', description: '', price: '9.50', ingredients: '' },
            { name: 'Mix Chirashi', description: '', price: '9.50', ingredients: '' },

        ]
    },
    {
        label: 'salad & poke bowls',
        description: '',
        menuItems: [
            { name: 'Salmon Poke', description: '', price: '8.99', ingredients: '' },
            { name: 'Tuna Poke', description: '', price: '9.50', ingredients: '' },
            { name: 'Chicken Katsu Poke', description: '', price: '8.50', ingredients: '' },
            { name: 'Kaiso Salad', description: '(vg)', price: '5.50', ingredients: '' },

        ]
    },
    {
        label: 'sashimi',
        description: '5 slices of raw fish',
        menuItems: [
            { name: 'Salmon Sashimi', description: '', price: '5.50', ingredients: '' },
            { name: 'Tuna Sashimi', description: '', price: '5.99', ingredients: '' },
            { name: 'Seabass Sashimi', description: '', price: '5.50', ingredients: '' },
        ]
    },
    {
        label: 'hosomaki',
        description: '6 pieces thin rolls of rice w/ seaweed on the outside',
        menuItems: [
            { name: 'Salmon Hosomaki', description: '', price: '4.99', ingredients: '' },
            { name: 'Tuna Hosomaki', description: '', price: '5.50', ingredients: '' },
            { name: 'Crab Hosomaki', description: '', price: '4.50', ingredients: '' },
            { name: 'Avocado Hosomaki', description: '(vg)', price: '4.50', ingredients: '' },
            { name: 'Mango Hosomaki', description: '(vg)', price: '4.50', ingredients: '' },
            { name: 'Cucumber Hosomaki', description: '(vg)', price: '4.50', ingredients: '' },

        ]
    },
    {
        label: 'futomaki',
        description: '6 pieces of large sushi rolls',
        menuItems: [
            { name: 'Special Futomaki', description: '', price: '12.50', ingredients: 'salmon / tuna / cucumber / avocado / japanese mayo / orange tobiko / prawn tempura' },
            { name: 'Chicken Katsu Futomaki', description: '', price: '11.50', ingredients: 'grilled chicken / avocado / cucumber / spicy mayo' },
            { name: 'Mimik Futomaki', description: '', price: '11.50', ingredients: 'soft crab fry / avocado / green onion / spicy mayo' },
        ]
    },
    {
        label: 'uramaki',
        description: '8 pieces inside out sushi roll',
        menuItems: [
            { name: 'Salmon Avocado Roll Uramaki', description: '', price: '8.99', ingredients: 'salmon / japanese mayo / sesame seeds / avocado' },
            { name: 'Salmon Crunchy Roll Uramaki', description: '', price: '9.50', ingredients: 'salmon skin/ cucumber / japanese mayo/ teriyaki sauce / sesame seeds' },
            { name: 'Spicy Tuna Cucumber Uramaki', description: '', price: '9.99', ingredients: 'tuna / sriracha / cucumber / masago / spring onion' },
            { name: 'California Roll Uramaki', description: '', price: '8.50', ingredients: 'crab meat / japanese mayo / cucumber / orange masago / avocado /sesame seeds' },
            { name: 'Ebi Katsu Roll Uramaki', description: '', price: '8.99', ingredients: 'ebi katsu/ orange tobiko / avocado / japanese mayo / fried onions / teriyaki sauce' },
            { name: 'Veg Roll Uramaki', description: '(vg)', price: '8.50', ingredients: 'avocado / carrot / cucumber / white sesame / japanese mayo' },
            { name: 'Philadelphia Roll Uramaki', description: '', price: '8.50', ingredients: 'salmon / cream cheese / avocado / tobiko' },
        ]
    },
    {
        label: 'mimik hot rolls',
        description: '6 pieces cooked sushi rolls',
        menuItems: [
            { name: 'Mimik Tiger Hot Roll', description: '', price: '10.50', ingredients: 'prawn tempura/ cream cheese / salmon / spring onion / japanese mayo / teriyaki sauce' },
            { name: 'Salmon Hot Roll', description: '', price: '10.50', ingredients: 'fried salmon / cream cheese / avocado / spicy mayo / teriyaki sauce' },
            { name: 'Salmon Lava Tempura Hot Roll', description: '', price: '10.50', ingredients: 'salmon maki tempura / salmon tartare / masago / sriracha / japanese mayo / spring onion' },
            { name: 'Tuna Lava Tempura Hot Roll', description: '', price: '10.50', ingredients: 'tuna maki tempura / tuna tartare / masago / sriracha / japanese mayo / spring onion' },
        ]
    },
    {
        label: 'mimik special rolls',
        description: '10 pieces special sushi rolls',
        menuItems: [
            { name: 'Rainbow Special Roll', description: '', price: '15.50', ingredients: 'salmon / tuna / avocado / japanese mayo / orange tobiko/ teriyaki sauce / fried onion / prawn tempura /spring onion' },
            { name: 'Black Tiger Special Roll', description: '', price: '12.50', ingredients: 'ebi katsu / avocado / japanese mayo / black sushi rice' },
            { name: 'Green Dragon Special Roll', description: '', price: '12.50', ingredients: 'prawn tempura / japanese mayo / orange tobiko / teriyaki sauce' },
            { name: 'Salmon Top Special Roll', description: '', price: '12.50', ingredients: 'salmon / japanese mayo / avocado / orange tobiko' },
            { name: 'Mimik Special Special Roll', description: '', price: '12.50', ingredients: 'salmon / cream cheese / mango / green onion / fried onion / black tobiko' },
            { name: 'Red Dragon Special Roll', description: '', price: '14.90', ingredients: 'prawn tempura, avocado, sliced tuna on top garnished with spicy truffle mayo, teriyaki sauce, fried sweet potato' },
            { name: 'Yamato Dragon Special Roll', description: '', price: '14.80', ingredients: 'prawn katsu, avocado, torched salmon nigiri w/ spicy mayo, soy glaze, sesame seeds, sweet potato garnish' },
            { name: 'Spicy Salmon Dragon Special Roll', description: '', price: '14.60', ingredients: 'salmon cucumber, topped with sliced salmon, sesame seeds, sriracha sauce, fried onions' },
            { name: 'Spicy Tuna Dragon Special Roll', description: '', price: '14.90', ingredients: 'tuna cucumber topped with sliced tuna garnished, sesame sauce, sriracha sauce, fried onions' },
            { name: 'Spicy Salmon Lava Special Roll', description: '', price: '13.60', ingredients: 'salmon marinated w/ sriracha, spring onion, cucumber, topping tartare salmon, garnish w/ tempura super crunc' },
        ]
    },
    {
        label: 'hot dishes',
        description: '',
        menuItems: [
            { name: 'Chicken Katsu Curry', description: '', price: '12.00', ingredients: 'deep fry chicken, japanese katsu sauce, served with rice salad' },
            { name: 'Prawn Katsu Curry', description: '', price: '13.00', ingredients: 'deep fry prawn, japanese katsu sauce, served with rice salad' },
            { name: 'Veg Katsu Curry', description: '', price: '10.00', ingredients: 'deep fry vegetable katsu, japanese katsu sauce, served with rice salad' },
            { name: 'Chicken Karaage', description: '', price: '12.00', ingredients: 'plain steamed rice, 5 pieces of marinated chicken in garlic and ginger, deep fried served with lime and spicy truffle mayo' },
            { name: 'Salmon Teriyaki', description: '', price: '13.00', ingredients: 'grilled salmon, steamed broccoli, plain steamed rice, teriyaki sauce' },
            { name: 'Beef Teriyaki', description: '', price: '13.00', ingredients: 'grilled black angus beef (150 g), steamed broccoli, plain steamed rice, teriyaki sauce' },
            { name: 'Chicken Teriyaki', description: '', price: '12.00', ingredients: 'grilled chicken, steamed broccoli, plain steamed rice, teriyaki sauce' },
        ]
    },
    {
        label: 'yakisoba noodles',
        description: 'stir fried thin, egg yakisoba noodles or thick, white udon noodles',
        menuItems: [
            { name: 'Seafood Noodles', description: '', price: '12.00', ingredients: 'king prawn, crabstick, scallops, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Chicken Noodles', description: '', price: '11.00', ingredients: 'grilled chicken, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Yasai Veg Noodles', description: '(vg)', price: '9.30', ingredients: 'mixed vegetable, fried tofu, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Beef Noodles', description: '', price: '13.00', ingredients: 'grilled beef, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Salmon Noodles', description: '', price: '13.00', ingredients: 'grilled salmon, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
        ]
    },
    {
        label: 'ramen',
        description: '',
        menuItems: [
            { name: 'Chicken Ramen', description: '', price: '12.50', ingredients: 'fried chicken, noodles, egg, fresh onions, mixed vegetables, ramen soup' },
            { name: 'Duck Ramen', description: '', price: '13.99', ingredients: 'deep fried duck, mixed vegetables, noodles, ramen soup' },
            { name: 'Seafood Ramen', description: '(vg)', price: '13.99', ingredients: 'prawn, egg, noodles, mixed vegetables, ramen soup' },
            { name: 'Veg Ramen', description: '', price: '9.99', ingredients: 'noodles, mixed vegetables, egg' },
        ]
    },
    {
        label: 'mimik combo',
        description: '',
        menuItems: [
            { name: 'Tuna Selection Economic', description: '(18 pcs)', price: '14.50', ingredients: '2 tuna maki, 2 tuna nigiri, 4 tuna sashimi, 2 cucumber maki, 6 mix uramaki, 2 tuna lava' },
            { name: 'Salmon Selection Economic', description: '(18 pcs)', price: '13.99', ingredients: '2 salmon maki, 2 salmon nigiri, 4 salmon sashimi, 2 cucumber maki, 6 mix uramaki, 2 salmon lava' },
            { name: 'Mix Sushi Box', description: '(24 pcs)', price: '17.99', ingredients: '6 salmon maki, 4 salmon avocado roll, 6 avocado maki, 8 tempura roll' },
            { name: 'Salmon Seared Box', description: '(16 pcs)', price: '13.99', ingredients: '4 salmon seared nigiri, 4 seared sashimi, 8 salmon hot roll' },
            { name: 'Mix California Box', description: '(16 pcs)', price: '12.99', ingredients: '4 california roll, 4 salmon crunchy roll, 4 salmon avocado roll, 4 tempura roll' },
            { name: 'Veg Box', description: '(18 pcs)', price: '13.99', ingredients: '4 cucumber maki, 4 tofu, 2 avocado nigiri, 2 gunkan, 2 veg uramaki, 2 avocado uramaki, 2 mango lava' },
            { name: 'Hot Box', description: '(18 pcs)', price: '14.50', ingredients: '4 chicken gyoza, 4 veg spring roll, 5 salmon hot maki, 5 mango top salmon hot with philadelphia' },
        ]
    },
    {
        label: 'sushi platters',
        description: 'platters to share',
        menuItems: [
            { name: 'Mimik Sushi Platter', description: '(35 pcs)', price: '27.99', ingredients: '4 pcs mix nigiri, 4 pcs mix sashimi, 2 chicken gyoza, 2 veg spring roll, 2 tempura prawn special, 5 mix hosomaki, 5 mix lava tempura, 5 salmon hot maki, 3 mix uramaki, 3 mix top roll' },
            { name: 'All You Can Eat Platter', description: '(70 pcs)', price: '54.99', ingredients: '8 pcs mix nigiri, 8 pcs mix sashimi, 4 chicken gyoza, 4 veg spring roll, 4 tempura prawn special, 10 mix hosomaki, 10 mix lava tempura, 10 salmon hot maki, 6 mix uramaki, 6 mix top roll' },
            { name: 'Mimik Veg Platter', description: '(32 pcs)', price: '21.99', ingredients: '6 pcs avocado maki, 6 pcs cucumber maki, 3 pcs avocado nigiri, 3 pcs cucumber nigiri, 8 pcs uramaki veg, 4 pcs cucumber jojou with wakami, 2 pcs tofu' },
        ]
    },
    {
        label: 'extras',
        description: '',
        menuItems: [
            { name: 'Plain Rice', description: '', price: '2.99', ingredients: '' },
            { name: 'Sushi Rice', description: '', price: '2.99', ingredients: '' },
            { name: 'Curry Sauce', description: '', price: '2.50', ingredients: '' },
        ]
    },
    {
        label: 'desserts',
        description: '',
        menuItems: [
            { name: 'Mochi', description: '', price: '3.99', ingredients: 'mango / strawberry / vanilla' },
            { name: 'Dorayaki', description: '', price: '2.99', ingredients: 'chocolate / vanilla' },
        ]
    }
]

const richmondBranchMenu = [
    {
        label: 'starters',
        description: '',
        menuItems: [
            { name: 'Miso Soup', description: '(GF)', price: '3.50', ingredients: '' },
            { name: 'Edamame Normal', description: '(VG)', price: '3.99', ingredients: '' },
            { name: 'Edamame Spicy', description: '(VG)', price: '3.99', ingredients: '' },
            { name: 'Chicken Karaage', description: '(5 pc)', price: '5.00', ingredients: '' },
            { name: 'Duck Spring Roll', description: '(3 pc)', price: '4.50', ingredients: '' },
            { name: 'Veg Spring Roll', description: '(3 pc, VG)', price: '3.99', ingredients: '' },
            { name: 'Prawn Gyoza', description: '(5 pc)', price: '5.99', ingredients: '' },
            { name: 'Chicken Gyoza', description: '(5 pc)', price: '5.50', ingredients: '' },
            { name: 'Duck Gyoza', description: '(5 pc)', price: '5.99', ingredients: '' },
            { name: 'Veg Gyoza', description: '(5 pc, VG)', price: '4.99', ingredients: '' },
            { name: 'Prawn Tempura', description: '(5 pc)', price: '8.50', ingredients: '' },
        ]
    },
    {
        label: 'nigiri',
        description: '2 pieces of rice pillow with toppings',
        menuItems: [
            { name: 'Salmon Nigiri', description: '', price: '3.50', ingredients: '' },
            { name: 'Tuna Nigiri', description: '', price: '3.99', ingredients: '' },
            { name: 'Seabass Nigiri', description: '', price: '3.50', ingredients: '' },
            { name: 'Eel Nigiri', description: '', price: '4.99', ingredients: '' },
            { name: 'Prawn Nigiri', description: '', price: '3.99', ingredients: '' },
            { name: 'Mango Nigiri', description: '(vg)', price: '3.50', ingredients: '' },
            { name: 'Avocado Nigiri', description: '(vg)', price: '3.50', ingredients: '' },
        ]
    },
    {
        label: 'sashimi',
        description: '5 slices of raw fish',
        menuItems: [
            { name: 'Salmon Sashimi', description: '', price: '5.50', ingredients: '' },
            { name: 'Tuna Sashimi', description: '', price: '5.99', ingredients: '' },
            { name: 'Seabass Sashimi', description: '', price: '5.50', ingredients: '' },
        ]
    },
    {
        label: 'gunkan',
        description: '2 pieces of nori-wrapped sushi rice topped with a choice of ingredient',
        menuItems: [
            { name: 'Salmon Gunkan', description: '', price: '3.00', ingredients: '' },
            { name: 'Tuna Gunkan', description: '', price: '3.50', ingredients: '' },
            { name: 'Masago Gunkan', description: '', price: '3.00', ingredients: '' },
            { name: 'Wakame Gunkan', description: '(vg)', price: '2.50', ingredients: '' },
        ]
    },
    {
        label: 'tartare',
        description: 'ground raw fish with sauce and dressing',
        menuItems: [
            { name: 'Salmon Tartare', description: '', price: '7.50', ingredients: '' },
            { name: 'Tuna Tartare', description: '', price: '7.99', ingredients: '' },
        ]
    },
    {
        label: 'tataki',
        description: '5 slices of seared fish',
        menuItems: [
            { name: 'Salmon Tataki', description: '', price: '6.99', ingredients: '' },
            { name: 'Tuna Tataki', description: '', price: '7.50', ingredients: '' },
        ]
    },
    {
        label: 'temaki',
        description: 'a traditional Japanese handroll w/ rice & fillings',
        menuItems: [
            { name: 'Salmon Temaki', description: '', price: '5.50', ingredients: 'salmon / japanese mayo / sesame seeds / avocado' },
            { name: 'Tuna Temaki', description: '', price: '5.99', ingredients: 'tuna / japanese mayo / green onion' },
            { name: 'Salmon Philadelphia Temaki', description: '', price: '5.50', ingredients: 'salmon / cream cheese / avocado / spring onion / tobiko' },
            { name: 'Vegatarian Temaki', description: '(vg)', price: '4.50', ingredients: 'avocado / cucumber / salad / mango' },
        ]
    },
    {
        label: 'chirashi',
        description: 'sushi rice topped with raw fish',
        menuItems: [
            { name: 'Salmon Chirashi', description: '', price: '5.50', ingredients: '' },
            { name: 'Tuna Chirashi', description: '', price: '5.99', ingredients: '' },
            { name: 'Mix Chirashi', description: '', price: '5.50', ingredients: '' },

        ]
    },
    {
        label: 'salad & poke bowls',
        description: '',
        menuItems: [
            { name: 'Salmon Poke', description: '', price: '8.88', ingredients: '' },
            { name: 'Tuna Poke', description: '', price: '9.50', ingredients: '' },
            { name: 'Chicken Katsu Poke', description: '', price: '8.50', ingredients: '' },
            { name: 'Japanese Kanikama Salad', description: '', price: '8.50', ingredients: '' },
            { name: 'Kaiso Salad', description: '(vg)', price: '5.50', ingredients: '' },

        ]
    },
    {
        label: 'hosomaki',
        description: '6 pieces thin rolls of rice w/ seaweed on the outside',
        menuItems: [
            { name: 'Salmon Hosomaki', description: '', price: '4.99', ingredients: '' },
            { name: 'Tuna Hosomaki', description: '', price: '5.50', ingredients: '' },
            { name: 'Crab Hosomaki', description: '', price: '4.50', ingredients: '' },
            { name: 'Avocado Hosomaki', description: '(vg)', price: '4.50', ingredients: '' },
            { name: 'Mango Hosomaki', description: '(vg)', price: '4.50', ingredients: '' },
            { name: 'Cucumber Hosomaki', description: '(vg)', price: '4.50', ingredients: '' },

        ]
    },
    {
        label: 'futomaki',
        description: '6 pieces of large sushi rolls',
        menuItems: [
            { name: 'Special Futomaki', description: '', price: '10.50', ingredients: 'salmon / tuna / cucumber / avocado / japanese mayo / orange tobiko / prawn tempura' },
            { name: 'Chicken Katsu Futomaki', description: '', price: '9.50', ingredients: 'grilled chicken / avocado / cucumber / spicy mayo' },
            { name: 'Mimik Futomaki', description: '', price: '9.50', ingredients: 'soft crab fry / avocado / green onion / spicy mayo' },
        ]
    },
    {
        label: 'uramaki',
        description: '8 pieces inside out sushi roll',
        menuItems: [
            { name: 'Salmon Avocado Roll Uramaki', description: '', price: '8.99', ingredients: 'salmon / japanese mayo / sesame seeds / avocado' },
            { name: 'Salmon Crunchy Roll Uramaki', description: '', price: '9.50', ingredients: 'salmon skin/ cucumber / japanese mayo/ teriyaki sauce / sesame seeds' },
            { name: 'Spicy Tuna Cucumber Uramaki', description: '', price: '9.99', ingredients: 'tuna / sriracha / cucumber / masago / spring onion' },
            { name: 'California Roll Uramaki', description: '', price: '8.50', ingredients: 'crab meat / japanese mayo / cucumber / orange masago / avocado /sesame seeds' },
            { name: 'Ebi Katsu Roll Uramaki', description: '', price: '8.99', ingredients: 'ebi katsu/ orange tobiko / avocado / japanese mayo / fried onions / teriyaki sauce' },
            { name: 'Veg Roll Uramaki', description: '(vg)', price: '8.50', ingredients: 'avocado / carrot / cucumber / white sesame / japanese mayo' },
            { name: 'Philadelphia Roll Uramaki', description: '', price: '8.50', ingredients: 'salmon / cream cheese / avocado / tobiko' },
        ]
    },
    {
        label: 'mimik hot rolls',
        description: '6 pieces cooked sushi rolls',
        menuItems: [
            { name: 'Mimik Tiger Hot Roll', description: '', price: '10.50', ingredients: 'prawn tempura/ cream cheese / salmon / spring onion / japanese mayo / teriyaki sauce' },
            { name: 'Salmon Hot Roll', description: '', price: '8.50', ingredients: 'fried salmon / cream cheese / avocado / spicy mayo / teriyaki sauce' },
            { name: 'Salmon Lava Tempura Hot Roll', description: '', price: '8.50', ingredients: 'salmon maki tempura / salmon tartare / masago / sriracha / japanese mayo / spring onion' },
            { name: 'Tuna Lava Tempura Hot Roll', description: '', price: '8.50', ingredients: 'tuna maki tempura / tuna tartare / masago / sriracha / japanese mayo / spring onion' },
        ]
    },
    {
        label: 'mimik special rolls',
        description: '10 pieces special sushi rolls',
        menuItems: [
            { name: 'Rainbow Special Roll', description: '', price: '15.50', ingredients: 'salmon / tuna / avocado / japanese mayo / orange tobiko/ teriyaki sauce / fried onion / prawn tempura /spring onion' },
            { name: 'Black Tiger Special Roll', description: '', price: '12.50', ingredients: 'ebi katsu / avocado / japanese mayo / black sushi rice' },
            { name: 'Green Dragon Special Roll', description: '', price: '12.50', ingredients: 'prawn tempura / japanese mayo / orange tobiko / teriyaki sauce' },
            { name: 'Salmon Top Special Roll', description: '', price: '12.50', ingredients: 'salmon / japanese mayo / avocado / orange tobiko' },
            { name: 'Mimik Special Special Roll', description: '', price: '12.50', ingredients: 'salmon / cream cheese / mango / green onion / fried onion / black tobiko' },
            { name: 'Red Dragon Special Roll', description: '', price: '14.90', ingredients: 'prawn tempura, avocado, sliced tuna on top garnished with spicy truffle mayo, teriyaki sauce, fried sweet potato' },
            { name: 'Yamato Dragon Special Roll', description: '', price: '14.80', ingredients: 'prawn katsu, avocado, torched salmon nigiri w/ spicy mayo, soy glaze, sesame seeds, sweet potato garnish' },
            { name: 'Spicy Salmon Dragon Special Roll', description: '', price: '14.60', ingredients: 'salmon cucumber, topped with sliced salmon, sesame seeds, sriracha sauce, fried onions' },
            { name: 'Spicy Tuna Dragon Special Roll', description: '', price: '14.90', ingredients: 'tuna cucumber topped with sliced tuna garnished, sesame sauce, sriracha sauce, fried onions' },
            { name: 'Spicy Salmon Lava Special Roll', description: '', price: '13.60', ingredients: 'salmon marinated w/ sriracha, spring onion, cucumber, topping tartare salmon, garnish w/ tempura super crunc' },
        ]
    },
    {
        label: 'hot dishes',
        description: '',
        menuItems: [
            { name: 'Chicken Katsu Curry', description: '', price: '9.00', ingredients: 'deep fry chicken, japanese katsu sauce, served with rice salad' },
            { name: 'Prawn Katsu Curry', description: '', price: '11.00', ingredients: 'deep fry prawn, japanese katsu sauce, served with rice salad' },
            { name: 'Veg Katsu Curry', description: '', price: '9.00', ingredients: 'deep fry vegetable katsu, japanese katsu sauce, served with rice salad' },
            { name: 'Chicken Karaage', description: '', price: '11.00', ingredients: 'plain steamed rice, 5 pieces of marinated chicken in garlic and ginger, deep fried served with lime and spicy truffle mayo' },
            { name: 'Salmon Teriyaki', description: '', price: '13.00', ingredients: 'grilled salmon, steamed broccoli, plain steamed rice, teriyaki sauce' },
            { name: 'Beef Teriyaki', description: '', price: '15.00', ingredients: 'grilled black angus beef (150 g), steamed broccoli, plain steamed rice, teriyaki sauce' },
            { name: 'Chicken Teriyaki', description: '', price: '11.00', ingredients: 'grilled chicken, steamed broccoli, plain steamed rice, teriyaki sauce' },
        ]
    },
    {
        label: 'yaki noodles',
        description: 'stir fried thin, egg yakisoba noodles or thick, white udon noodles',
        menuItems: [
            { name: 'Seafood Noodles', description: '', price: '12.00', ingredients: 'king prawn, crabstick, scallops, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Chicken Noodles', description: '', price: '11.00', ingredients: 'grilled chicken, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Yasai Veg Noodles', description: '(vg)', price: '9.30', ingredients: 'mixed vegetable, fried tofu, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Beef Noodles', description: '', price: '13.00', ingredients: 'grilled beef, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
            { name: 'Salmon Noodles', description: '', price: '13.00', ingredients: 'grilled salmon, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
        ]
    },
    {
        label: 'sushi platters',
        description: 'platters to share',
        menuItems: [
            { name: 'Salmon Lover', description: '', price: '14.99', ingredients: '15 total pc, tempura maki (6pc), salmon nigiri (3pc), salmon sashimi (6pc)' },
            { name: 'Tuna Lover', description: '', price: '15.99', ingredients: '15 total pc, tempura maki & tuna temaki avocado (6pc), tuna nigiri (3pc), tuna sashimi (6pc)' },
            { name: 'Yamato Maki Set', description: '', price: '23.99', ingredients: '30 total pc, special dragon rolls, salmon tempura maki, california uramaki, salmon nigiri' },
            { name: 'Tokyo Freestyle', description: '', price: '17.99', ingredients: '16 total pc, gunkan special (2pc), nigiri (3pc), special dragin rolls (6pc), assorted sashimi (5pc)' },
            { name: 'Yamato Freestyle', description: '', price: '39.99', ingredients: '36 total pc, gunkan special (4pc), nigiri (4pc), tempura maki (8pc), special dragon rolls (8pc), mixed sashimi (12pc)' },
            { name: 'Osaka Freestyle', description: '', price: '75.99', ingredients: '70 total pc, uramaki, hosomaki, gunkan special, nigiri, tempura maki, hot sushi tiger roll, special rolls, mixed sashimi' },
            { name: 'Veg Freestyle', description: '(vg)', price: '15.99', ingredients: '16 total pc, green veg dragon rolls, avocado tempura maki, veg uramaki, avocado nigiri' },
        ]
    },
    {
        label: 'extras',
        description: '',
        menuItems: [
            { name: 'Plain Rice', description: '', price: '2.99', ingredients: '' },
            { name: 'Sushi Rice', description: '', price: '2.99', ingredients: '' },
            { name: 'Curry Sauce', description: '', price: '2.50', ingredients: '' },
        ]
    },
    {
        label: 'desserts',
        description: '',
        menuItems: [
            { name: 'Mochi', description: '', price: '3.99', ingredients: 'mango / strawberry / vanilla' },
            { name: 'Dorayaki', description: '', price: '2.99', ingredients: 'chocolate / vanilla' },
        ]
    }

]

export { actonBranchMenu, richmondBranchMenu }