import React from 'react'
import { animateScroll as scroll} from "react-scroll"
import { ArrowUpCircleFill } from 'react-bootstrap-icons'

function ScrollToTopButton({ className }) {
    const options = {
      duration: 500,
      smooth: true,
    }

    const scrollToTop = () => {
        scroll.scrollToTop(options);
      };

  return (
    <>
        <ArrowUpCircleFill onClick={scrollToTop} className={className} />
    </>
  )
}

export default ScrollToTopButton