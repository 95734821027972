import { Outlet, useLocation, useSearchParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { OrderDataContext } from "./context/OrderDataContext"
import { animateScroll as scroll } from "react-scroll"

import Banner from "./Components/Banner"
import Footer from "./Components/Footer"
import Header from "./Components/Header"
import Newsletter from "./Components/Newsletter"
import Order from "./Pages/Order"

// React SEO
import { HelmetProvider } from "react-helmet-async"
import SEO from "./Components/SEO"
import ScrollToTopButton from "./Components/ScrollToTopButton"

function Layout() {
  const location = useLocation();
  const saved_order = (localStorage.getItem('mimik_order') !== null) ? JSON.parse(localStorage.getItem('mimik_order')) : []; // declared to get the current number of items of the local storage
  const [orderCount, setOrderCount] = useState(saved_order.length); // this prevents the number of items from disappearing when website is refreshed
  const updateOrderCount = (orderCnt) => {
    setOrderCount(orderCnt)
  }
  const [isScrolled, setIsScrolled] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isCheckoutSuccess = searchParams.get('success') && searchParams.get('cs_id') ? true : false
  const isCheckoutCancel = searchParams.get('canceled') && searchParams.get('cs_id') ? true : false
  const isCheckoutPage = (location.pathname == '/checkout') ? true : false;

  // handle scroll to toggle scroll-to-top button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div id="App">
      <HelmetProvider>
        <SEO
          title="Mimik Sushi - Japanese Cuisine With a Modern Take"
          description="Home page of Mimik Sushi"
          name="Mimik Sushi"
          type="Home Page"
        />

        <ScrollToTopButton className={`scroll-to-top-btn ${isScrolled ? 'd-block' : ''}`} />
        {!isCheckoutSuccess ?
          <>
            <OrderDataContext.Provider value={orderCount}>
              <Header />
            </OrderDataContext.Provider>
            {/* <Banner/>  */}
          </>
          : <></>
        }
        <main id="main">
          <Outlet context={[updateOrderCount]} />
        </main>
        {!isCheckoutSuccess ?
          <>
            <Newsletter />
            <Footer />
          </>
          : <></>}
      </HelmetProvider>

    </div>

  )
}

export default Layout

/**
 * I'm just trying to test if the layout of the website still works if it's outside the Components folder. That's why it's not in the folder.
 */