import React, { useState } from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2'
import { GeoAltFill, TelephoneFill, EnvelopeFill, ClockFill, MapFill } from 'react-bootstrap-icons';
import { Element } from 'react-scroll';

import { contactApi } from '../api/contact'
import SEO from '../Components/SEO';

function Contacts() {

    const [errMsg, setErrMsg] = useState("");
    const [successSendMsg, isSuccessSendMsg] = useState(false);
    const [submitting, isSubmitting] = useState(false)
    const contactForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required'),
            subject: Yup.string().required('Subject is required'),
            message: Yup.string().required('Message is required'),
        }),
        onSubmit: (values) => {
            isSubmitting(true)
            contactApi.addContact(values).then((data) => {
                if (data.success) {
                    Swal.fire({
                        text: data.msg,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#ff6766',
                    })
                    isSuccessSendMsg(true)
                    isSubmitting(false)
                } else {
                    setErrMsg(data.msg);
                    isSubmitting(false)
                    return;
                }
            });
        }
    });

    return (
        <>
            <SEO
                title="Mimik Sushi - Contact Us"
                description="If you have any enquiries, pleause use this form to contact us."
                name="Mimik Sushi - Contact Us"
                type="website"
                url="https://www.mimiksushi.com/contacts"
            />
            <Element name="contacts-section">

                <section id="contact" className="contact">

                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <p><span>CONTACT US</span></p>
                            {
                                successSendMsg ?
                                    <div className='text-center'>
                                        <p className="fs-1 text-success">Your message has been sent.Thank you!</p>
                                    </div>
                                    : ''
                            }
                        </div>

                        <div className="section-text">
                            <p>If you have any enquiries, please use this form to contact us</p>
                        </div>

                        <div className="container" data-aos="fade-up" data-aos-delay="100">
                            <div className="row gy-4">

                                <div className="col-md-6">
                                    <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
                                        <GeoAltFill className="icon flex-shring-0" />
                                        <div>
                                            <h3>Address</h3>
                                            <p><b>Richmond</b>: 4 Church Ct, Richmond TW9 1JL</p>
                                            <p><b>Acton</b>: 269 High St, London W3 9BT</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                        <TelephoneFill className="icon flex-shring-0" />
                                        <div>
                                            <h3>Call Us</h3>
                                            <p><b>Richmond</b>: 07928 289728</p>
                                            <p><b>Acton</b>: 020 7167 8370 </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="400">
                                        <EnvelopeFill className="icon flex-shring-0" />
                                        <div>
                                            <h3>Email Us</h3>
                                            <p><b>Richmond</b>: mimiksushirichmond@gmail.com </p>
                                            <p><b>Acton</b>: mimiksushi@gmail.com </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="500">
                                        <ClockFill className="icon flex-shring-0" />
                                        <div>
                                            <h3>Opening Hours<br /></h3>
                                            <p><b>Richmond</b>:<strong> Tue-Sun:</strong> 12PM - 10PM; <strong>Monday:</strong> Closed</p>
                                            <p><b>Acton</b>:<strong> Mon-Sun:</strong> 12PM - 10PM</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>




                        {
                            !successSendMsg ?
                                <>
                                    <form className="php-email-form p-3 p-md-4" data-aos="fade-up" data-aos-delay="600">
                                        <div className="row">
                                            <div className='col-xl-12'>
                                                {errMsg ?
                                                    <div className='book-a-table error-message'>{errMsg}</div>
                                                    : ''}
                                            </div>
                                            <div className="col-xl-6 form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Name*"
                                                    required
                                                    onChange={contactForm.handleChange}
                                                    onBlur={contactForm.handleBlur}
                                                    value={contactForm.values.name}
                                                />
                                                {contactForm.touched.name && contactForm.errors.name ? <p className='field-err-msg'>{contactForm.errors.name}</p> : null}

                                            </div>
                                            <div className="col-xl-6 form-group">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email*"
                                                    required
                                                    onChange={contactForm.handleChange}
                                                    onBlur={contactForm.handleBlur}
                                                    value={contactForm.values.email}
                                                />
                                                {contactForm.touched.email && contactForm.errors.email ? <p className='field-err-msg'>{contactForm.errors.email}</p> : null}

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="subject"
                                                id="subject"
                                                placeholder="Subject*"
                                                required
                                                onChange={contactForm.handleChange}
                                                onBlur={contactForm.handleBlur}
                                                value={contactForm.values.subject}
                                            />
                                            {contactForm.touched.subject && contactForm.errors.subject ? <p className='field-err-msg'>{contactForm.errors.subject}</p> : null}

                                        </div>
                                        <div className="form-group form-message" id="form-message">
                                            <textarea
                                                className="form-control"
                                                name="message" rows="5"
                                                placeholder="Message*"
                                                required
                                                onChange={contactForm.handleChange}
                                                onBlur={contactForm.handleBlur}
                                                value={contactForm.values.message}
                                            ></textarea>
                                            {contactForm.touched.message && contactForm.errors.message ? <p className='field-err-msg'>{contactForm.errors.message}</p> : null}
                                        </div>
                                        <div className="my-3">
                                        </div>

                                        <div className="text-center">
                                            <button type="submit" className='btn-send-msg' onClick={contactForm.handleSubmit}>
                                                {
                                                    !submitting ?
                                                        'Send Message'
                                                        : <img src="assets/img/icon/spinner-solid.svg" />
                                                }
                                            </button>
                                        </div>

                                    </form>
                                </>
                                : ''
                            /*End Contact Form */
                        }

                    </div>
                </section>
            </Element>
        </>
    )
}

export default Contacts