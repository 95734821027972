import PropTypes from "prop-types";

import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, name, type, url }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="https://www.mimiksushi.com/assets/img/mimik-logo.png"/>

      {/* Open Graph tags */}
      <meta property="og:image" content="https://www.mimiksushi.com/assets/img/mimik-logo.png" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="640" />

      {/* Twitter tags */}
      <meta property="twitter:card" ccontent="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:creator" content={name} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:image" content="https://www.mimiksushi.com/assets/img/mimik-logo.png" />
      <meta property="twitter:description" content={description} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
}

export default SEO;
