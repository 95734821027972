function Banner() {
    return (
        <section id="hero" className="hero d-flex align-items-center section-bg">
            <div className="container">
                <div className="justify-content-between gy-5">
                    <div lg={8} className="order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start banner-text">
                        <h2 data-aos="fade-up" data-aos-delay="300" className='banner-main-text'> Japanese cuisine <br/>with a Modern take</h2>
                        {/* <p data-aos="fade-up" data-aos-delay="100"></p> */}
                        {/* <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                            <a href="assets/img/vids/vid-1.mp4"
                                className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span className='banner-minor-text'>Watch
                                    Video</span></a>
                        </div> */}
                    </div>
                    <div className="col-lg-5 order-1 order-lg-2 text-center text-lg-start">
                        <img src="assets/img/banner-3" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="300" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner