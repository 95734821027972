import { useState } from 'react'

import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';

function MenuContent({value}) {
    const saved_order = (localStorage.getItem('mimik_order') !== null) ? JSON.parse(localStorage.getItem('mimik_order')) : [];
    const [addedOrder, setOrder] = useState(saved_order);
    const [updateOrderCount] = useOutletContext();

    const addToOrder = (menu) => {
        // let menu_key = menu['name'].replace(/\s+/g, '_').toLowerCase();
        let menu_key = menu['menu_key']; // Creating a new property menu_key inside the menu items
        menu['menu_key'] = menu_key;
        let check_exist = addedOrder.some(item => item.menu_key === menu.menu_key);
        if(!check_exist){
            setOrder([...addedOrder, menu]);
        }
    }
    
    const saveOrder = () => {
        updateOrderCount(addedOrder.length)
        localStorage.setItem('mimik_order', JSON.stringify(addedOrder));
    }

    const checkIfOrderExist = (menu_key) => {
        let exist = addedOrder.some(order => order.menu_key === menu_key);
        return exist;
    }

    useEffect(() => {
        saveOrder()
    }, [addedOrder])

    const renderedSection = value.map((section) => {
        const menuItems = section.menuItems.map((menuItem) => {

            let menu_key = menuItem['name'].replace(/\s+/g, '_').toLowerCase();
            menu_key = section.label + '_' + menu_key;
            menuItem['menu_key'] = menu_key; // Creating a new property inside the menu item and storing a value inside it
            let exist = checkIfOrderExist(menu_key)

            return (

                <div className="col-lg-4 menu-item" key={menuItem.name}>
                    <h4>{menuItem.name} <em className="quantity">{menuItem.description}</em></h4>
                    <p className="ingredients">
                        {menuItem.ingredients}
                    </p>
                    <p className="price">
                        £{menuItem.price}
                    </p>
                    {/* <a
                        onClickCapture ={() => addToOrder(menuItem)}
                        className={exist ? "added" : ""}
                    >
                        <FontAwesomeIcon icon={faCartShopping} /> 
                        {exist ? <span>Added</span> : <span>Add to cart</span>}
                    </a> */}
                    
                </div>

            )
        })

        return (
            <div className="tab-pane fade active show pt-5" id={section.label} key={section.label}>
                <div className="tab-header text-center">
                    <h3>{section.label}</h3>
                    <h6><em>{section.description}</em></h6>
                </div>
                <div className="row gy-5">
                    {menuItems}
                </div>
            </div>
        )


    })
    return (
        <> 
            {renderedSection}
        </>
    )
}

export default MenuContent