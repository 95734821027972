import { NavLink, useLocation } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { List, XCircle } from "react-bootstrap-icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-scroll";

// - icons

import { OrderDataContext } from "../context/OrderDataContext";

function Header() {
    const orderCount = useContext(OrderDataContext);
    const [isScrolled, setIsScrolled] = useState(false);
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;

    // Determine if the user is on the home page
    const isHome = useLocation().pathname === "/";

    // toggle mobile navigation
    const toggleMobileNav = () => {
        setMobileNavActive(!mobileNavActive);
        document.body.classList.toggle("mobile-nav-active", !mobileNavActive);
    }

    // handle scroll to toggle navbar background
    useEffect(() => {
        const handleScroll = () => {
            if (isHome) {
                setIsScrolled(window.scrollY > 50);
            }
        }

        // Add scroll listener only on the home page
        if (isHome) {
            window.addEventListener('scroll', handleScroll);

            // reset navbar background to transparent when going back to Home page
            setIsScrolled(false);
        } else {
            setIsScrolled(true);
        }

        return () => {
            if (isHome) {
                window.removeEventListener('scroll', handleScroll);
            }
        }

    }, [isHome])

    // Scroll to top when path changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location])

    return (
        <header id="header" className={`header fixed-top d-flex align-items-center ${isScrolled ? 'scrolled' : ''}`}>
            <Container className="container d-flex align-items-center justify-content-between" data-aos="fade-down">
                <NavLink className="logo d-flex align-items-center me-auto me-lg-0 nav" to="/"><img src="assets/img/mimik-logo.png" alt="" /></NavLink>

                <Navbar id="navbar" className="navbar nav">
                    <ul>
                        <li>
                            <Link to="menu-section" smooth={true} duration={100}>
                                <NavLink to='/menu'>MENU</NavLink>
                            </Link>
                        </li>
                        <li>
                            <Link to="order-section" smooth={true} duration={100}>
                                <NavLink to='/order' >ORDER</NavLink>
                            </Link>
                        </li>
                        <li>
                            <Link to="contacts-section" smooth={true} duration={100}>
                                <NavLink to='/contacts' >CONTACT</NavLink>
                            </Link>
                        </li>
                        <li>
                            <Link to="locations-section" smooth={true} duration={100}>
                                <NavLink to='/locations' >LOCATIONS</NavLink>
                            </Link>
                        </li>
                    </ul>
                </Navbar>

                <NavLink className="btn-book-a-table nav" to="/booking">Book Now</NavLink>
                {/* <NavLink className="btn-book-a-table nav cart-menu" to="/checkout"><FontAwesomeIcon icon={faCartShopping} /> <span>{orderCount}</span> </NavLink> */}
                <i className={`mobile-nav-toggle mobile-nav-show ${mobileNavActive ? 'mobile-nav-active' : ''}`} onClick={toggleMobileNav}><List /></i>
                <i className={`mobile-nav-toggle mobile-nav-hide ${mobileNavActive ? 'd-block' : 'd-none'} `} onClick={toggleMobileNav}><XCircle /></i>
            </Container>
        </header>
    )
}

export default Header   