import { useEffect } from "react"
import { Facebook, Instagram, Tiktok } from "react-bootstrap-icons"
import { Link } from "react-router-dom";

function Footer() {
    const currentYearFooter = new Date().getFullYear();

    useEffect(() => {
        var preloader = document.querySelector('#preloader');
        if (preloader) {
            preloader.remove();
        }
    }, []);
    return (
        <>
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-1 col-md-2 footer-links d-flex align-items-center justify-content-center">
                            <Link to="/menu" className="text-decoration-none">
                                MENUS
                            </Link>
                        </div>

                        <div className="col-lg-1 col-md-2 footer-links d-flex align-items-center justify-content-center">
                            <Link to="/order" className="text-decoration-none">
                                ORDER
                            </Link>
                        </div>

                        <div className="col-lg-1 col-md-2 footer-links d-flex align-items-center justify-content-center">
                            <Link to="/contacts" className="text-decoration-none">
                                CONTACT
                            </Link>
                        </div>

                        <div className="col-lg-1 col-md-2 footer-links d-flex align-items-center justify-content-center">
                            <Link to="locations" className="text-decoration-none">
                                LOCATIONS
                            </Link>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <div className="social-links d-flex justify-content-center">
                                <a href="https://www.tiktok.com/@mimiksushi" className="tiktok" target="_"><Tiktok /></a>
                                <a href="https://www.facebook.com/profile.php?id=100083613935638" className="facebook" target="_"><Facebook /></a>
                                <a href="https://www.instagram.com/mimiksushi_richmond/" className="instagram" target="_"><Instagram /></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Mimik Sushi</span></strong> {currentYearFooter}. All Rights Reserved
                    </div>
                </div>
                <div className="container text-center">
                    <p className="author">
                        Website made by <a href="https://www.linkedin.com/in/mlsanz/" target="_blank" rel="noreferrer" className="text-decoration-none">Mond</a>
                    </p>
                </div>
            </footer>
            {/* <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}
            <div id="preloader"></div>
        </>
    )
}

export default Footer